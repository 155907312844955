@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-quaternary
----------*/

.c-heading-quaternary {
	font-weight: bold;
	font-size: 1.6rem;
	line-height: var(--line-height-s);
	color: var(--color-text);

	@include m.mq-pc {
		font-size: 2rem;
	}
}
