@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	order list
----------*/
$_root: '.c-orderlist';

.c-orderlist {
	counter-reset: orderlist;
	font-size: 1.4rem;

	> li {
		padding-left: 2em;
		text-indent: -2.5em;

		&::before {
			counter-increment: orderlist;
			content: counter(orderlist)'.';
			display: inline-block;
			margin-right: .5em;
			font-weight: bold;
			min-width: 2em;
			text-align: right;
		}

		+ li {
			margin-top: 20px;
		}
	}

	@include m.mq-pc {
		font-size: 1.6rem;
	}
}
