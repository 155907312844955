@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	category top billboard
----------*/
$_root:'.c-billboard-catetop';

.c-billboard-catetop {
	position: relative;
	z-index: 2;

	&__inner {
		@include m.inner-content;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		position: relative;
		min-height: 450px;
		padding-top: 20px;
		padding-bottom: 60px;
		overflow: hidden;
	}

	&__title {
		font-family: var(--font-raj);
		font-weight: 500;
		font-size: 5.2rem;
		line-height: 1;
		color: #fff;

		&-sub {
			margin-top: 8px;
			font-weight: 500;
			font-size: 1.8rem;
			line-height: var(--line-height-s);
			color: #fff;
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}
	}

	@include m.mq-pc {
		&__inner {
			min-height: 550px;
			padding-top: 40px;
			padding-bottom: 80px;
		}

		&__title {
			font-size: 9rem;

			&-sub {
				font-size: 2.6rem;
			}
		}
	}

	@media print {
		&__image {
			display: none;
		}
	}
}
