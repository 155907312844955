@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	text
----------*/

.c-text {
	font-size: 1.4rem;
	line-height: var(--line-height-l);
	color: var(--color-text);

	@include m.mq-pc {
		font-size: 1.6rem;
	}
}
