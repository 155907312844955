@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	single column
----------*/
$_root: '.g-center-col';

.g-center-col {
	@include m.mq-sp {
		&__item + &__item {
			margin-top: 40px;
		}
	}

	@include m.mq-pc {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 50px;

		&__item {
			flex: 1 1 percentage( math.div(2,3) );
			max-width: percentage( math.div(2,3) ); // コンテンツ幅の3/2想定（1200に対して800）
		}

		&--small {
			#{$_root}__item {
				flex-basis: percentage( math.div(1,3) );
				max-width: percentage( math.div(1, 3) );
			}
		}

		&--2up {
			#{$_root}__item {
				flex-basis: percentage( math.div(1,3) );
				max-width: percentage( math.div(1, 3) );
			}
		}
	}
}
