@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	lead
----------*/

.c-lead {
	font-weight: 500;
	font-size: 1.8rem;
	line-height: var(--line-height-l);
	color: var(--color-text);

	@include m.mq-pc {
		font-size: 2.6rem;
	}
}
