@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button text link
----------*/
$_root: '.c-button-textlink';

.c-button-textlink {
	&__inner {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 60px;
		border-top: 1px solid #222;
		border-bottom: 1px solid #222;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		position: relative;
		text-decoration: none;
		text-align: center;
		color: var(--color-text);
		cursor: pointer;

		> span {
			padding: 20px 40px 20px 10px;
			font-weight: 500;
			font-size: 1.4rem;
			line-height: var(--line-height-s);
			color: inherit;
		}
	}

	&__icon {
		display: block;
		width: 16px;
		height: 16px;
		position: absolute;
		top: 50%;
		right: 8px;
		font-size: 16px;
		transform: translateY(-50%);
	}

	@include m.mq-pc {
		&__inner {
			> span {
				font-size: 1.6rem;
				padding-left: 20px;
			}
		}

		&__icon {
			right: 20px;
		}
	}
}
