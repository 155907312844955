@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	grid
----------*/

.g-grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 40px;

	@include m.mq-sp {
		&--sp2up {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@include m.mq-pc {
		display: grid;
		gap: 50px;

		&--2up {
			grid-template-columns: repeat(2, 1fr);
		}

		&--3up {
			grid-template-columns: repeat(3, 1fr);
		}

		&--sp2up,
		&--4up {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
