@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	billboard
----------*/
$_root:'.c-billboard';

.c-billboard {
	position: relative;
	z-index: 2;

	&__inner {
		@include m.inner-content;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		position: relative;
		min-height: 300px;
		padding-top: 20px;
		overflow: hidden;
	}

	&__title {
		font-weight: 500;
		font-size: 4.2rem;
		line-height: var(--line-height-s);

		&-sub {
			margin-bottom: 8px;
			font-weight: 500;
			font-size: 1.5rem;
			line-height: var(--line-height-s);
			color: #7D7C7D;
		}
	}

	@include m.mq-pc {
		&__inner {
			min-height: 360px;
			padding-top: 40px;
		}

		&__title {
			font-size: 5.2rem;

			&-sub {
				font-size: 1.8rem;
			}
		}
	}
}

