@use 'sass:math';
@use '../../_mixin' as m;

/* --------------------------------------------------------
* http://v4.eir-parts.net/内　main.css
* -------------------------------------------------------*/

/* --------------------------------------------------------
* 全体設定
* eirBlock
* eir年タイトル
* EIRリスト
* Year Controller
* Newstype Controller
* Note
* Governance
* IR Calendar
* share
* RSS
* yuhoCsv
* BoxList
* Matrix
* HTMLカレンダー
* エリアカスタマイズ用
* エリアカスタマイズ用（カテゴリアイコンあり体裁）
* -------------------------------------------------------*/


/* --------------------------------------------------------
* 全体設定
* -------------------------------------------------------*/
.eir *,
.eirTarget_zipDownload *,
.eirTarget_pdfNote *,
.eirTarget_pnexNote *,
.eirTarget_yuhoNote *,
.eirTarget_rss_59846 *,
.eirTarget_yuhoCsv_741478 *,
.eirTarget_latestFiscalYm_eirSampleArea *,
.eirTarget_latestQuarter_eirSampleArea *,
.eir_controller_year *,
.eir_controller_newstype *,
.eirTarget_calendarImg *{
    box-sizing:border-box;
    line-height: normal;
}


/* --------------------------------------------------------
* eirBlock
* -------------------------------------------------------*/
.s_eirBlock {
    display:block;
}

section.s_eirBlock {}

.s_eirBlock:last-child {
    padding-bottom:0;
}
.s_eirBlock:first-child {
    margin-top:0;
}
.s_eirQuarterBlock {
    margin-bottom: 60px;
}
.s_eirBlock:last-child .s_eirQuarterBlock {
    margin-bottom: 0;
}


/* --------------------------------------------------------
* eir年タイトル
* -------------------------------------------------------*/
.s_eirHeading {}
.s_eirQuarter {}


/* --------------------------------------------------------
* EIRリスト
* -------------------------------------------------------*/
.s_eirItem {
    display: flex;
}
.s_eirList {
	margin:0;
	padding:0;
	list-style:none;
}

@media screen and (max-width: 768px) {
    .s_eirItem {
        flex-wrap: wrap;
    }
}


/*　EIRリスト > アイテム
--------------------------------------------------------*/
.s_eirList_item {
    display: flex;
    margin-top:20px;
    padding-top:20px;
    border-top: 1px solid #CCC;
}
.s_eirList_item:first-child {
    margin-top:0;
    border:0;
}
.s_eirList_item {
    display: flex;
}
.s_eirModule_eirItem > * {
    margin-left: 20px;
}
.s_eirModule_eirItem > *:first-child {
    margin-left: 0;
}
.s_eirList_item-all_link {
    display: block;
}

@media screen and (max-width: 768px) {
    .s_eirList_item {
        flex-direction: column;
    }
    .eir .s_eirList_item {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .s_eirList_item::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        order: 6;
    }
}


/*　EIRリスト > アイテム（訂正）
--------------------------------------------------------*/
.eirItem_child {
    border-top:0;
    margin-top:0;
}
.eirItem_child .eirItem_head {
    visibility:hidden;
}
.eirItem_child .s_eirModule_titleBox {
    position: relative;
    padding-left: 20px;
    margin-left: 20px;
}
.eirItem_child .s_eirModule_titleBox::before{
    content: "";
    display: inline-block;
    width: 10px;
    height: 8px;
    border: 2px solid #000;
    border-right: none;
    border-top: none;
    position: absolute;
    top: 0.2rem;
    left: 0;
}

@media screen and (max-width: 768px) {
    .eirItem_child .eirItem_head {
        display:none;
    }
}


/*　EIRリスト > アイテム　付与クラス一覧
--------------------------------------------------------*/
/*
.cat-news-tanshin,
.cat-news-yuho,
.cat-news-press,
.cat-news-pr,
.cat-news-announcement,
.cat-news-ir_material,
.cat-news-ir_material_for_fiscal_ym,
.cat-news-cgr_ds,
.cat-news-cgr_do,
.cat-news-gms,
.cat-news-custom_type {
}
.cat-sub-announcement,
.cat-sub-ir_material,
.cat-sub-ir_material_for_fiscal_ym,
.cat-sub-custom_type1,
.cat-sub-id0 {
}
.cat-num-1,
.cat-num-2,
.cat-num-3,
.cat-num-4 {
}
.s_eirList_item-url,
.s_eirList_item-pdf,
.s_eirList_item-xlsx,
.s_eirList_item-xls,
.s_eirList_item-docx,
.s_eirList_item-doc,
.s_eirList_item-pptx,
.s_eirList_item-ppt,
.s_eirList_item-zip,
.s_eirList_item-space {
}
.s_eirList_item-q_100,
.s_eirList_item-q_30,
.s_eirList_item-q_20,
.s_eirList_item-q_10,
.s_eirList_item-q_0 {
}
.s_eirList_item-thum_true,
.s_eirList_item-thum_false {
}
*/


/*　EIRリスト > 日付
--------------------------------------------------------*/
.s_eirModule_date {
    white-space: nowrap;
    /* 日付の表示形式によって幅の修正が必要 */
    /* width: 6rem; */
}


/*　EIRリスト > サムネイル
--------------------------------------------------------*/
.s_eirModule_thumbnail {
    width: 200px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    padding: 0;
}
.s_eirModule_thumbnail_img {
	width:100%;
	height:auto;
	margin:0;
	border:1px solid #ccc;
}


/*　EIRリスト > カテゴリアイコン
--------------------------------------------------------*/
.s_eirModule_category {
    width: 100px;
}
.s_eirModule_category_icon {
	display:inline-block;
	width:100px;
	background-color:#333;
	vertical-align:top;
	padding:3px 0;
	text-align:center;
	color:#FFF;
    font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', arial, helvetica, clean, sans-serif;
    font-size: 10px;
}


/*　EIRリスト > カテゴリアイコン（画像）
--------------------------------------------------------*/
.s_eirModule_category_image {
    /* display: flex; */
    display: none;
    align-items: center;
    height: 24px;
}
.s_eirModule_category_image::before {
    content: '';
    width: 40px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
}
.cat-news-tanshin .s_eirModule_category_image::before {
    background-image: url(../img/category_icon/tanshin.gif);
}
.cat-news-press .s_eirModule_category_image::before {
    background-image: url(../img/category_icon/press.gif);
}
.cat-news-pr .s_eirModule_category_image::before {
    background-image: url(../img/category_icon/pr.gif);
}
.cat-news-yuho .s_eirModule_category_image::before {
    background-image: url(../img/category_icon/yuho.gif);
}
.cat-sub-material .s_eirModule_category_image::before {
    background-image: url(../img/category_icon/material.gif);
}


/*　EIRリスト > タイトルBOX
--------------------------------------------------------*/
.s_eirModule_titleBox {
    display:block;
    flex-grow: 1;
    /* 日付 + 余白 */
    /* width: calc(100% - (6rem + 20px)); */
}

/*.s_titleBox_title {
    display: inline;
    display: none;
}
*/

@media screen and (max-width: 768px) {
    .s_eirModule_titleBox {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
}


/*　EIRリスト > タイトル（書類名）
--------------------------------------------------------*/
.s_eirModule_title {
    display: inline;
}
.s_eirModule_title_link {}
.eirItem_title_text {}
.s_eirModule_title_label {}


/*　EIRリスト > 固定文言
--------------------------------------------------------*/
.s_eirModule_title_static {
    display: inline;
    /*display: none;*/
}
.s_eirModule_title_static::before {
    content: '';
    display: inline;
}


/*　EIRリスト > アイテムタイプアイコン
--------------------------------------------------------*/
.s_eirModule_type {}
.s_eirModule_type_icon {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 10px;
    margin-left:5px;
    line-height: 15px;
    color:#FFF;
    font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', arial, helvetica, clean, sans-serif;
    font-size: 10px;
}


/*　EIRリスト > アイテムタイプアイコン（CSS）
--------------------------------------------------------*/
.s_eirModule_type_icon-pdf {
    background-color:#ee3d23;
}
.s_eirModule_type_icon-url {
    background-color:#2196F3;
    display:none;
}
.s_eirModule_type_icon-doc,
.s_eirModule_type_icon-docx {
    background-color:#3F51B5;
    display:none;
}
.s_eirModule_type_icon-xls,
.s_eirModule_type_icon-xlsx {
    background-color:#4CAF50;
    display:none;
}
.s_eirModule_type_icon-ppt,
.s_eirModule_type_icon-pptx {
    background-color:#FF5722;
    display:none;
}
.s_eirModule_type_icon-zip {
    background-color:#607D8B;
    display:none;
}
.s_eirModule_type_icon-space {
    display:none;
}


/*　EIRリスト > アイテムタイプアイコン（画像）
--------------------------------------------------------*/
.s_eirModule_type_figure {
    display:none;
    margin:0 5px 0 0;
}
.s_eirModule_type_figure_image {
    height:15px;
    vertical-align:middle;
}
.s_eirModule_type_figure_image-url,
.s_eirModule_type_figure_image-doc,
.s_eirModule_type_figure_image-docx,
.s_eirModule_type_figure_image-xls,
.s_eirModule_type_figure_image-xlsx,
.s_eirModule_type_figure_image-ppt,
.s_eirModule_type_figure_image-pptx,
.s_eirModule_type_figure_image-zip,
.s_eirModule_type_figure_image-space {
    display:none;
}


/*　EIRリスト > サイズ表示（KB）
--------------------------------------------------------*/
.s_eirModule_size {}


/*　EIRリスト > ページ数
--------------------------------------------------------*/
.s_eirModule_page_num {}


/*　EIRリスト > NEWアイコン
--------------------------------------------------------*/
.s_eirModule_new {}
.s_eirModule_new_icon {
    display: inline-block;
    background-color:#F90;
    vertical-align: middle;
    padding: 0px 10px;
    line-height: 15px;
    color:#FFF;
    font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', arial, helvetica, clean, sans-serif;
    font-size: 10px;
}


/*　EIRリスト > NEWアイコン（画像）
--------------------------------------------------------*/
.s_eirModule_new_image {
    /* display: flex; */
    display: none;
    align-items: center;
    height: 24px;
}
.s_eirModule_new_image::before {
    content: '';
    width: 26px;
    height: 12px;
    display: block;
    background-repeat: no-repeat;
    background-image: url(../img/new_icon.gif);
}


/*　EIRリスト > テキスト
--------------------------------------------------------*/
.s_eirModule_text {}


/*　EIRリスト > コメント
--------------------------------------------------------*/
.s_eirModule_comment {
    font-size: 80%;
}


/*　基本パターン（タイトルBOX内を表示）
--------------------------------------------------------*/
.s_titleBox_type,
.s_titleBox_size,
.s_titleBox_page_num,
.s_titleBox_new {
    display: inline;
}
.s_titleBox_text,
.s_titleBox_comment {
    display: block;
}
.s_titleBox_title_link_static,
.s_titleBox_title_text_static {
    display: none;
}
.s_eirList_item_type,
.s_eirList_item_size,
.s_eirList_item_page_num,
.s_eirList_item_new,
.s_eirList_item_text,
.s_eirList_item_comment {
    display: none;
}


/* EIRリスト > 表示順変更
* -------------------------------------------------------*/
.s_eirList_item_date {
    order: 1;
}
.s_eirList_item_thumbnail {
    order: 2;
}
.s_eirList_item_category {
    order: 3;
}
.s_eirList_item_category_second {
    order: 4;
}
.s_eirList_item_titleBox {
    order: 5;
}
.s_eirList_item_type {
    order: 6;
}
.s_eirList_item_size {
    order: 7;
}
.s_eirList_item_page_num {
    order: 8;
}
.s_eirList_item_new {
    order: 9;
}
.s_eirList_item_text {
    order: 10;
}
.s_eirList_item_comment {
    order: 11;
}

/* --------------------------------------------------------
* Year Controller
* -------------------------------------------------------*/
.s_yearController {
    margin-bottom: 30px;
}
.s_eirSelect {
    padding:10px 40px 10px 10px;
    max-width: 100%;
}
.s_eirAnchor {
    display: flex;
    flex-wrap:wrap;
	margin:0;
	padding:0;
	list-style:none;
}
.s_eirAnchor li {
    margin-right:20px;
}
.s_eirAnchor li:last-child {
    margin-right:0;
}
.s_eirAnchor a:before {
    display:inline-block;
    content: "";
    border: 5px solid transparent;
    border-top: 5px solid #333;
    margin-right:5px;
}

@media screen and (max-width: 768px) {
    .s_eirAnchor {
        width:100%;
        justify-content: space-between;
    }
    .s_eirAnchor li {
        width:20%;
        margin-right:0;
        margin-top:2%;
    }
    .s_eirAnchor li a {
        width:auto;
    }
}


/* --------------------------------------------------------
* Newstype Controller
* -------------------------------------------------------*/
.s_newsTypeController {
	margin-bottom: 30px;
}
.s_eirTab {
    display: flex;
    margin:0;
    padding:0;
    list-style:none;
}
.s_eirTab li {
	width: 100%;
	border: 2px solid #333;
	background-color:#FFF;
}
.s_eirTab li + li {
	border-left-width: 0;
}
.s_eirTab li:last-child {
	margin-right:0;
}
.s_eirTab li a {
	display:block;
	width: 100%;
	height:100%;
	padding: 10px 10px;
	color:#333;
	text-align:center;
	text-decoration:none;
	outline: none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
}
.s_eirTab li.active a,
.s_eirTab li:hover a {
    color:#FFF;
    background-color:#333;
}

@media screen and (max-width: 768px) {
    .s_eirTab {
        width:100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .s_eirTab li {
        width:49%;
        /* margin-right:0; */
        margin-top:2%;
        border-left-width: 2px;
    }
    .s_eirTab li + li {
        border-left-width: 2px;
    }
    .s_eirTab li a {
        width:auto;
    }
}


/* --------------------------------------------------------
* Note
* -------------------------------------------------------*/
.eir_pdfNote_item,
.eir_pnexNote_item,
.eir_yuhoNote_item {}

.eir_pdfNote img,
.eir_pnexNote img {
    width: auto;
}

/* PDF注記
* -------------------------------------------------------*/
.eir_pdfNote {
    margin-top: 30px;
}
.eir_pdfNote {
    display: flex;
}
.eir_pdfNote_item {
    margin-left:20px;
}
.eir_pdfNote_item:first-child {
    margin-left:0;
}

/* PNEX注記
* -------------------------------------------------------*/
.eir_pnexNote {
    margin-top: 30px;
}
.eir_pnexNote_item-right {
    text-align:right;
}

/* 外部リンクアイコン*/
.eir_pnexNote_item:first-of-type a[target="_blank"]::after {}

/* 有報注記
* -------------------------------------------------------*/
.eir_yuhoNote {
    margin-top: 30px;
}

@media screen and (max-width: 768px) {
    .eir_pdfNote {
        flex-direction: column;
    }
    .eir_pdfNote_item {
        margin-left:0;
    }
    .eir_pdfNote_item:first-child {
        margin-bottom: 10px;
    }
}


/* --------------------------------------------------------
* Governance
* -------------------------------------------------------*/
.eir[class*="area_governance"] .s_eirList_item {
    display: block;
    text-align:right;
}
.eir[class*="area_governance"] .noDataText {
    display:none;
}
.eir[class*="area_governance"]  .s_eirModule_titleBox {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .eir[class*="area_governance"] .s_eirList_item {
        text-align:left;
    }
}

/* --------------------------------------------------------
* IR Calendar
* -------------------------------------------------------*/
.eirCalendar_figure {
	display:flex;
	justify-content: center;
	padding:0;
	max-width: 850px;
	width: 100%;
	margin: 0 auto;
}
.eirCalendar_image:nth-child(2) {
    margin-left:2px;
}
.eirCalendarNote {
    margin-top:30px;
}
.eirCalendar_image {
	width:50%;
}

@media screen and (max-width: 768px) {
	.eirCalendar_figure {
		flex-direction: column;
		max-width:424px;
		width:100%;
    }
	.eirCalendar_image {
	    width: 100%;
    }
	.eirCalendar_image:nth-child(2) {
		margin-left:0;
    }
}


/* --------------------------------------------------------
* share
* -------------------------------------------------------*/
.s_eir_MoreBtn_link a {
    background-color:#FFF;
    border:2px solid #333;
    color:#333;
    padding:15px;
    text-align:center;
    text-decoration:none;
    outline: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    margin-top:30px;
    display: block;
}
.s_eir_MoreBtn_link a:hover {
    color:#FFF;
	background-color:#333;
	text-decoration:none;
}


/* --------------------------------------------------------
* RSS
* -------------------------------------------------------*/
.eir_rss {
    text-align:right;
}
.eir_rss_image {
    width:16px;
}


/* --------------------------------------------------------
* yuhoCsv
* -------------------------------------------------------*/
.eir_yuhoCsv_item {
    margin-top:20px;
}
.eir_yuhoCsv_item:first-child {
    margin-top:0;
}
.eir_yuhoCsv_item_link {
    display:block;
    width:250px;
	background-color:#FFF;
	padding:15px;
	border:1px solid #333;
	color:#333;
	text-align:center;
	text-decoration:none;
	outline: none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
}
.eir_yuhoCsv_item_link:hover {
	color:#FFF;
	background-color:#333;
	text-decoration:none;
}

@media screen and (max-width: 768px) {
	.eir_yuhoCsv_item_link {
		width:100%;
    }
}


/* --------------------------------------------------------
* BoxList
* -------------------------------------------------------*/
.s_eirBoxList {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
}
.s_eirBoxList_item {
	width: 33.3%;
	/*★並べる数を変更する場合％変更*/
	border-left:#e8e8e8  solid 1px;
	border-bottom:#e8e8e8  solid 1px;
	padding: 20px;
}
.s_eirBoxList_item:nth-child(-n+3) {
	border-top:#e8e8e8  solid 1px;
}
.s_eirBoxList_item:nth-child(3n),
.s_eirBoxList_item:last-child {
	/*★並べる数を変更する場合(n)変更*/
	border-right:#e8e8e8  solid 1px;
}
.s_eirBoxList_item_inner {
	width: 100%;
	height: auto;
	position: relative;
}

/* BoxList > ヘッダー */
.s_eirBoxList_head::after {
	content: "";
	display: block;
	overflow: hidden;
	clear: both;
}

/* BoxList > ヘッダー > カテゴリアイコン */
.s_eirBoxList_head_categoryWrap {
	padding-bottom: 6px;
	margin-right: 4px;
	float: left;
}
.s_eirBoxList_head_categoryWrap::after {
	content: "";
	display: block;
	overflow: hidden;
	clear: both;
}
.s_eirBoxList_head_category_icon {
	margin: 0;
	display: block;
	float: left;
	width: 70px;
}
.s_eirBoxList_head_category_icon+.s_eirBoxList_head_category_icon {
	margin-left: 4px;
}
.s_eirBoxList_head_category_icon .s_eirModule_category_icon,
.s_eirBoxList_head_category_icon .s_eirModule_category_image {
    width: 100%;
}

/* BoxList > ヘッダー > 日付 */
.s_eirBoxList_head_dataWrap {
	float: right;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
    justify-content: flex-end;
	padding-bottom: 6px;
}
.s_eirBoxList_head_dataWrap::after {
	content: "";
	display: block;
	overflow: hidden;
	clear: both;
}
.s_eirBoxList_head_new {
	display: block;
	align-self:center;
	padding: 1px;
    text-align: center;
    margin-top: -2px;
}
.s_eirBoxList_head_date {
	display: block;
	align-self:center;
    margin-left: 5px;
}

/* BoxList > ボディ */
.s_eirBoxList_item_body {
	margin-top: 8px;
}
.s_eirBoxList_body_item {}

/* BoxList > ボディ > テキスト部分 */
.s_eirBoxList_body_textWrap {}
.s_eirBoxList_body_title {}
.s_eirBoxList_body_titleWrap {
	margin: 0;
	display: inline;
	word-break: break-all;
}
.s_eirBoxList_body_title_link {
	text-decoration: none;
}
.s_eirBoxList_body_title_link:hover {
	text-decoration: underline;
}
.s_eirBoxList_body_title_link_static,
.s_eirBoxList_body_title_text_static {
    display: none;
}
.s_eirBoxList_body_title_type {
	display: inline;
}
.s_eirBoxList_body_title_size {
	display: inline;
}
.s_eirBoxList_body_title_page {
	display: inline;
}
.eirUseText.s_eirBoxList_body_useText {
	margin-top: 6px;
	background: none;
}
.s_eirBoxList_body_comment {
	margin-top: 6px;
	font-size: 80%;
}

/* BoxList > ボディ > サムネイル部分 */
.s_eirBoxList_body_thumbWrap {
	margin: 0;
	padding: 0;
    width: 120px;
	float: right;
	/*★表示位置逆にする場合L⇔R変更*/
	margin-left: 10px;
	/*★表示位置逆にする場合L⇔R変更*/
}
.s_eirBoxList_body_thumb_img {
	width: 100%;
}

/* BoxList > 訂正・追加書類 */
.s_eirBoxList_item_inner-child {
	margin-top: 20px;
    padding-top: 12px;
    border-top: #e8e8e8 solid 1px;
}
.s_eirBoxList_item_inner-child .s_eirBoxList_head {
	display: none;
}
.s_eirBoxList_item_inner-child .s_eirBoxList_body_new {
	display:inline-block;
}

@media screen and (max-width: 768px) {
	.s_eirBoxList {
		display: block;
    }
	.s_eirBoxList::after {
		display: none;
    }
	.s_eirBoxList_item {
		width: 100%;
		padding: 20px;
		border-right: #e8e8e8 solid 1px;
    }
	.s_eirBoxList_item:nth-child(-n+3) {
		border-top:none;
    }
	.s_eirBoxList_item:first-child {
		border-top: #e8e8e8 solid 1px;
	}
	/* BoxList > ボディ > サムネイル部分 */
	.s_eirBoxList_body_thumbWrap {
		width: 100px;
	}
}


/* --------------------------------------------------------
* Matrix
* -------------------------------------------------------*/
.s_eirMatrix_table {
	border-collapse:collapse;
    width:100%;
}
.s_eirMatrix_th,
.s_eirMatrix_td {
    width:20%;
    padding:10px;
	border-style:solid;
	border-color:#d9d9d9;
	border-width:1px;
	justify-content: center;
	align-items: center;
	text-align:center;
}
.s_eirMatrix_th {
    background-color:#eee;
}

@media screen and (max-width: 768px){
    .s_eirMatrix_table {
    	width:100%;
    }
    .s_eirMatrix_th,
    .s_eirMatrix_td {
    	word-break:break-all;
    }
}

.eir-pager {
    letter-spacing: -0.5em;
    margin-top: 50px;
    margin-bottom: 80px;
    text-align: center;
}
.eir-pager .pagination {
    margin: 0;
}
.eir-pager span {
    display: inline-block;
    letter-spacing: normal;
    margin-right: 10px;
    margin-top: 5px;
}
.eir-pager span a {
    border: 1px solid #333;
    color: #333;
    display: inline-block;
    padding: 5px 10px;
}
.eir-pager span.current {
    border: 1px solid #eee;
    color: #eee;
    padding: 5px 10px;
}
.s_eirList_item_date_hidden {
    visibility: hidden;
}
.eirAccordionTrigger {
    background-image: none;
    cursor:pointer;
    padding:5px;
    background-color:#2a855c;
    color:#FFF;
    position:relative;
    margin: 0;
}
.eirAccordionTrigger::after {
    content:"＋";
    display:block;
    position:absolute;
    top:2px;
    right:10px;
}
.eirAccordionTrigger-open::after {
    content:"－";
}
.eirAccordionWrapper {
    display:none;
}
.eirAccordionWrapper-open {
    display:block;
}


/* --------------------------------------------------------
* HTMLカレンダー
* -------------------------------------------------------*/
.eir-calendar .eirHeading.s_eirHeading {
    text-align: center;
    font-size: 24px;
    padding: 12px 0;
    letter-spacing: 1px;
    background-color: #00519C;
    color: #fff;
    margin-bottom: 0;
}
.eir-calendar .inner {
    position: relative;
}
.eirCalendarTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: calc(100% + 2px);
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
}
.eirCalendarTable thead th {
    padding: 2px 0;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border: 1px solid #e5e5e5;
    font-weight: 400;
}
.eirCalendarTable td {
    background: #999999;
    vertical-align: top;
    text-align: center;
    color: #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    height: 196px;
}
.eirCalendar_label {
    display: table;
    width: 86%;
    margin: 24px auto 0;
}
.eirCalendar_label span {
    display: table-cell;
    height: 57px;
    vertical-align: middle;
    /* background: #fff; */
    color: #fff;
    line-height: 1.3;
}
.eirCalendar_year {
    text-align: center;
    margin: 7px 0 0;
    font-size: 13px;
    font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    display: none;
}
.eirCalendar_month {
    font-size: 20px;
    margin: 25px 0 0;
    text-indent: 10px;
    line-height: 1;
}

/* .eirCalendar_year+.eirCalendar_month {
    margin-top: 0;
} */

.eirCalendar_month span {
    font-size: 30px;
    font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    margin: 0 3px 0 0;
}

@media screen and (max-width: 768px) {
    .eir-calendar .inner {
        overflow-x: auto;
    }
    .eir-calendar .scroll-container {
        width: calc(200% + 1px);
    }
    .eirCalendarTable td {
        height: 140px;
    }
    .eirCalendar_month span {
        font-size:23px;
    }
    .eirCalendar_month {
        margin-top:28px;
    }
}


/* --------------------------------------------------------
* エリアカスタマイズ用
* -------------------------------------------------------*/
/*
.エリアclass {}

@media screen and (max-width: 768px) {
    .エリアclass {}
}
*/


/* --------------------------------------------------------
* エリアカスタマイズ用（カテゴリアイコンあり体裁）
* -------------------------------------------------------*/
/*
.エリアclass .s_eirModule_titleBox {
    日付 +　余白 + カテゴリアイコン + 余白
    width: calc(100% - (6rem + 20px + 100px + 20px));
}

@media screen and (max-width: 768px) {
    .エリアclass .s_eirModule_titleBox {
        width: 100%;
    }
}
*/



/* QIR */
/*---------------------------------
  BASE SETTING
-----------------------------------*/
.qirArea {
	max-width: 1000px;
}


/*---------------------------------
  遡及修正削除
-----------------------------------*/
/* .qirBox_carefulNote_list_item-retrospective {
	display: none;
} */


/*---------------------------------
  PNEX注記：外部リンクアイコン
-----------------------------------*/
.qirBox_pnexNote_text:first-of-type a[target="_blank"]::after {}


/*---------------------------------
  Content:グラフ関連
-----------------------------------*/

/*　テーブル
-----------------------------------*/
.qirContent_table table,
.qirContent_table th,
.qirContent_table td {
	border-color: #c7d5de;
}
.qirContent_table th,
.qirContent_table td {
	padding: 10px 4px;
}
.qirContent_table thead th {
	background-color: #758fa0;
	color: #fff;
}
.qirContent_table tbody th {
	background-color: #758fa0;
	color: #fff;
}

@media screen and (max-width:750px) {
	.qirContent_table {
		overflow-x:auto;
	}
	.qirArea-responsive .qirContentWrapper {
		display: block;
		margin-top: 40px;
	}
	.qirArea-responsive .qirContent {
		width: 100%;
		margin:0 auto 30px;
	}
	.qirArea-responsive .qirContent:last-child {
		margin-bottom: 0;
	}
	.qirArea-responsive .qirScrollTable {
		width: 100%;
		overflow-y: scroll;
	}
	.qirArea-responsive .qirScrollTable .qirTable {
		min-width: 600px;
	}
}


/*---------------------------------
  凡例個別設定
-----------------------------------*/
.qirArea-twocolumn .qirContent.qirContent-id201,
.qirArea-twocolumn .qirContent.qirContent-id202 {
    width:100%;
}
.qirContent-id101 .qirContent_legend {
    visibility:hidden;
}
.qirContent-id102 .qirContent_legend,
.qirContent-id122 .qirContent_legend  {
    display: none;
}

@media screen and (max-width:750px) {
    .qirContent-id101 .qirContent_legend {
        display: none;
    }
}

/* --------------------------------------------------------
* ベースコンポネ用オーバライド領域
* -------------------------------------------------------*/

/* 汎用リスト */
.s_eirList_item-all_link {
	margin: 0;
	padding: 0;
}

.s_eirModule_date_time {
	font-size: 1.4rem;
}

.s_eirModule_category {
	width: 80px;
}

.s_eirModule_category_icon {
	width: 80px;
	font-family: inherit;
	background: none;
	color: var(--color-txt);
	border: #333 solid 1px;
	padding: 1px 0 2px;
	vertical-align: bottom;
}

.s_eirModule_eirItem {
	padding: 25px 0;
	margin-top: 0;

	> *{
		margin-left: 15px;
	}
}

.s_eirList_item {
	padding-top: 0;
	margin-top: 0;
}

.s_eirList_item:first-child {
	border-top: 1px solid #CCC;
}

.s_eirList_item:last-child {
	border-bottom: 1px solid #CCC;
}

.eirItem_child {
	margin-bottom: 25px;

	.s_eirModule_eirItem {
		padding: 0;
	}

	&:last-child {
		padding-bottom: 25px;
		margin-bottom: 0;
	}
}

.eirItem_title_link {
	padding: 25px 0;
	color: var(--color-txt);

	&:hover {
		opacity: .4;
		transition: .3s;
	}
}

.s_eirList_item_date{
	& + *{
		margin-left: 18px;
	}
}

.s_eirModule_title_link,
.s_eirModule_title_label {
	color: var(--color-txt-link);
	font-size: 1.4rem;
}

.eirItem_title_text {
	.s_eirModule_title_label {
		color: var(--color-txt);
	}
}

.s_eirModule_type_icon {
	display: none;
}

.s_eirModule_type_figure {
	display: none;
	font-family: iconfont !important;
	speak: never;
	font-style: normal !important;
	font-size: inherit;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none;
	line-height: 1;
	color: inherit;
	margin: 0 5px;
	padding: 0;

	&_image {
		display: none;
	}
}

.s_eirModule_type_icon-pdf {
	& + .s_eirModule_type_figure {
		display: inline-block;

		&::before {
			//content: "\e91e";
			content: var(--icon-pdf);
			font-size: 1.6rem;
			vertical-align: text-top;

			@include m.mq-sp {
				font-size: 1.4rem;
				position: relative;
				top: 1px;
			}
		}
	}
}

.s_eirModule_titleBox {
	font-size: 1.4rem;
	line-height: var(--line-height-l);

	@include m.mq-sp {
		padding-top: 0;
		margin-left: 0;
	}
}

.s_eirModule_titleBox {
	&::before{
		border-width: 3px;
		top: .5em;
	}
}

.s_eirModule_comment {
	margin: 10px 0 0 0;
	font-size: 1.2rem;
	line-height: var(--line-height-l);
}

.s_eirModule_comment_item {
	strong {
		font-weight: bold;
	}
}

.eirItem_child {
	padding-left: 15px;

	.s_eirModule_titleBox {
		&::before{
			border-width: 3px;
			top: .5em;
		}
	}

	@include m.mq-sp {
		padding-left: 0;
	}
}

.eirCalendarNote {
	margin: 40px 0 0 0;

	@include m.mq-sp {
		margin: 20px 0 0 0;
	}
}

/* プルダウン */
.s_yearController {
	margin-bottom: 45px;
	position: relative;
	width: 240px;

	@include m.mq-sp {
		width: 100%;
	}

	&::after {
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		right: 20px;
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
		border-bottom: 2px solid #333;
		border-right: 2px solid #333;
	}
}

.s_eirSelect {
	min-width: 240px;
	height: 64px;
	border: #999 solid 1px;
	border-radius: 5px;
	color: var(--color-txt);
	padding: 10px 40px 10px 24px;
	appearance: none;
	position: relative;
	z-index: 1;
	font-weight: 500;
	cursor: pointer;
	line-height: var(--line-height-s);
	font-family: var(--font-sans);

	&::-ms-expand {
		display: none;
	}

	@include m.mq-sp {
		width: 100%;
	}
}

/* 見出し */
.s_eirHeading {
	font-size: 3.2rem;
	padding: 0 0 20px 0;
	margin: 0 0 40px 0;
	position: relative;

	> span{
		padding: 0;
	}

	&::before {
		position: absolute;
		content: '';
		width: 80px;
		height: 4px;
		background: var(--color-border);
		border-left: none;
		bottom: 0;
		left: 0;
		padding: 0;
	}

	@include m.mq-sp {
		font-size: 2.4rem;
		padding: 0 0 14px 0;

		&::before {
			width: 60px;
		}
	}
}

/* タブ */
.s_eirTab {
	justify-content: space-between;
	flex-wrap: wrap;

	@include m.mq-pc {
		border-bottom: #194192 solid 2px;
	}

	@include m.mq-sp {
		li {
			&:nth-child(n+3) {
				margin-bottom: 0;
			}
		}
	}
}

.s_eirTab li {
	width: calc((100% - 6px) / 4);
	margin: 0;
	border: none;

	@include m.mq-sp {
		width: calc((100% - 2px) / 2);
		margin-top: 1px;
	}
}

.s_eirTab li a {
	background: var(--color-bg);
	color: var(--color-txt-caption);
	width: 100%;
	padding: 10px;
	font-size: 1.6rem;
	line-height: var(--line-height-s);
	display: flex;
    align-items: center;
    justify-content: center;

	@include m.mq-pc {
		font-size: 1.8rem;
		border-radius: 5px 5px 0 0;
		padding: 17px 10px;
	}
}

.s_eirTab li.active {
	pointer-events: none;
    cursor: default;
}

.s_eirTab li.active a {
	background: #194192;
	color: #FFF;
	pointer-events: none;
    cursor: default;
}

/* 注釈 */
.eir_pnexNote_item-right {
	padding: 15px 21px 0 0;
	text-align: right;

	@include m.mq-sp {
		padding-right: 0;
	}
}

.eir * + .eir_pnexNote {
	@include m.mq-sp {
		margin: 25px 0 0 0;
	}
}

.eir_pnexNote_item a {
	color: var(--color-txt-link);
	text-decoration: underline;
}

.eir_pnexNote_item {
	font-size: 1.6rem;
    line-height: var(--line-height-l);
}

.eir_pdfNote {
	margin-top: 50px;

	@include m.mq-sp {
		margin-top: 25px;
	}
}

.eir_yuhoNote {
	margin-top: 48px;

	@include m.mq-sp {
		margin-top: 25px !important;
	}
}

.eir_pnexNote {
	margin-top: 15px;

	@include m.mq-sp {
		margin-top: 10px;
	}
}

.eir_pdfNote_item {
	font-size: 1.4rem;
	line-height: var(--line-height-s);
	margin-left: 20px;

	@include m.mq-sp {
		margin-left: 0;
	}
}

.eir_pdfNote + .eir_pnexNote{
	margin-top: 30px;

	@include m.mq-sp {
		margin-top: 20px;
	}
}

/* 一括ダウンロードボタン */
.g-irZip-button-container {
	margin: 30px 0 0 0;

	@include m.mq-pc {
		margin: 50px 0 0 0;
		min-width: 450px;
		text-align: center;

		&__item {
			display: inline-block;
		}

		.c-button__inner{
			min-width: 450px;
		}

	}
}

/* カレンダー画像 */
.g-irCalendar-image-container {
	&__item {
		img {
			max-width: 100%;
		}
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 4px;
		}

		&__item {
			width: calc(50% - 2px);
		}
	}

	@include m.mq-sp {
		&__item {
			&:last-child {
				margin: 20px 0 0 0;
			}
		}

	}
}

/* q-ir override */
.qir {

	line-height: var(--line-height-l);

	.qirArea {
		max-width: 1200px;
	}

	.qirContent_label {
		font-weight: bold;
		font-size: 2rem;
		line-height: var(--line-height-s);
		margin: 0 0 20px 0;
		padding: 0 0 0 15px;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			width: 6px;
			height: 28px;
			background: var(--color-txt);
			left: 0;
			top: 0;
		}


		@include m.mq-pc {
			font-size: 2.4rem;
			padding: 0 0 0 22px;

			&::before {
				top: 4px;
			}
		}

	}

	.qirArea-twocolumn .qirContent {
		width: calc(50% - 20px);

		@include m.mq-pc {
			&:nth-child(n+3) {
				margin-top: 80px;
			}
		}

		@include m.mq-sp {
			width: 100%;

			& + .qirContent{
				margin: 40px 0 0 0;
			}
		}
	}

	.qirContent_unit {
		font-size: 1.5rem;
	}

	.qirContent_graph {
		margin: 12px 0 0 0;
	}

	.qirContent_table {
		thead th{
			background-color: var(--color-txt-caption);
			border-color: var(--color-border);
			color: #fff;
		}

		tbody th {
			border-color: var(--color-border);
			background-color: var(--color-bg);
			color: inherit;
		}
	}

	.qirContent_note_list_item-dynamic {
		font-size: inherit;
		text-indent: -3em;
		padding-left: 3em;
	}

	* + .qirBox{
		margin: 50px 0 0 0;

		@include m.mq-sp {
			margin: 25px 0 0 0;
		}
	}

	.qirBox_asteriskNote_item {
		padding-left: 1.2em;

		&::before{
			text-indent: -1.2em;
		}
	}

	.qirBox_carefulNote_title {
		margin: 0 0 5px 0;
	}

	.qirBox_carefulNote_list_item {
		padding-left: 1.2em;

		&::before{
			text-indent: -1.2em;
		}
	}

	.qirBox_pnexNote_text {
		a[href*="pronexus.co.jp"] {
			text-decoration: underline;
		}
	}

	.pnexIcon {
		padding: 15px 21px 0 0;

		@include m.mq-sp {
			padding-right: 0;
		}
	}

}

/* スクロールテーブル */
.g-ir-spscr-container{
	@include m.mq-sp {
		.qirContent {
			&::before {
				content: "";
				display: block;
				margin-bottom: 12px;
				padding-top: 32px;
				background: url(/assets/images/table_swipe.svg) no-repeat center;
				background-size: contain;
			}
		}

		.qirContent_table {
			overflow-x: auto;
			table {
				width: 900px;
			}
		}
	}
}

/* 個別ページ override */

/* IRカレンダー */
.g-irCalendar-container {
	.eir{
		margin: 80px 0 0 0;
	}

	@include m.mq-sp {
		.eir{
			margin: 40px 0 0 0;
		}
	}
}

/* 配当の推移 */
.g-irDividend-container {
	.qirContent_table thead th,
	.qirContent_table tbody th {
		padding: 10px;

		&:first-child {
			min-width: 10em;
		}
	}

	.qirContent_table_xaxis {
		> br{
			display: none;
		}
	}


}

/* コーポレート・ガバナンス */
.g-irGovernance-container {
	.s_eirModule_date {
		display: none;
	}

	@include m.mq-sp {
		.eir[class*=area_governance] .s_eirList_item {
			text-align: right;
		}
	}
}









