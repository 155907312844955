@use 'sass:math';

// color
$colors: (
	theme-green:#44BAA0,
	theme-yellow: #FFCE00,
	theme-cyan: #16C1F4,
	theme-orange: #FD9E05,
	theme-blue: #5639F2,
	theme-lime: #C3D310,
	theme-slateblue: #507DE2,
	border: #DDDDDD,
	bg: #BBBBBB,
	caution: #F00000,
	text: #222222,
	text-caption: #888888,
	text-link: #16C1F4
);

// iconfont vars
$icons: (
	blank: "\EA01",
	calendar: "\EA02",
	chev-cir-down: "\EA03",
	chev-cir-left: "\EA04",
	chev-cir-right: "\EA05",
	chev-cir-up: "\EA06",
	chev-down: "\EA07",
	chev-left: "\EA08",
	chev-right: "\EA09",
	chev-sq-down: "\EA0A",
	chev-sq-left: "\EA0B",
	chev-sq-right: "\EA0C",
	chev-sq-up: "\EA0D",
	chev-up: "\EA0E",
	global: "\EA0F",
	ir: "\EA10",
	library: "\EA11",
	mail: "\EA12",
	subnav-toggle: "\EA13"
);


// SPビュー
@mixin mq-sp {
	@media (max-width: 767px) {
		@content;
	}
}

// PCビュー
@mixin mq-pc {
	@media (min-width: 768px) {
		@content;
	}
}

// ホバー
@mixin mq-hover {
	@media (hover: hover) {
		@content;
	}
}

// 基本コンテンツ幅設定
@mixin inner-content {
	width: 100%;
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 5.3%;
	padding-right: 5.3%;

	@include mq-pc {
		min-width: 1280px;
		padding-left: 40px;
		padding-right: 40px;
	}
}

// リンクアイコン
@mixin link-icon {
	@include iconfont-default;
	content: map-get($icons, 'chev-right');
	display: block;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #222;
	text-align: center;
	font-size: 18px;
	line-height: 80px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.2);
	text-decoration: none;
	color: #fff;
}

// easing
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
$ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
$ease-in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);

// clrfx
@mixin clrfx {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}


// ----------
// 便利系（例）
// ----------

// 直接アイコンフォントを設定する一式
@mixin iconfont-default {
	font-family: iconfont !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
}

// 上下のマージンを強制削除
@mixin margin-trim {
	> *:first-child {
		margin-top: 0 !important;
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}
}

@function hex2rgb($color) {
	@return red($color), green($color), blue($color);
}

// テキストリンクホバー <a>タグ内部に適用
@mixin textlink-hover {
	> span {
		background: linear-gradient( map-get($colors, 'text'), map-get($colors, 'text')) 100% 100% / 0 1px no-repeat;
		transition: background-size 0.4s $ease-in-out-cubic;
	}
	&:hover {
		> span {
			background-position: 0 100%;
			background-size: 100% 1px;
		}
	}
}
