@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button container
----------*/
$_root: '.g-button-container';

.g-button-container {
	margin: 0 auto;

	&__item {
		max-width: 366px;
	}

	@include m.mq-sp {
		&__item {
			margin-left: auto;
			margin-right: auto;

			+ #{$_root}__item {
				margin-top: 20px;
			}
		}

		&--left {
			#{$_root}__item {
				margin-left: 0;
				margin-right: auto;
			}
		}

		&--right {
			#{$_root}__item {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}

	@include m.mq-pc {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: stretch;
		gap: 40px 40px;

		&__item {
			flex: 1 1 366px;
		}

		&--3up {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}

		&--4up {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
		}

		// 2カラム
		&--2up {
			justify-content: center;
		}
	}

	/* 成り行き */
	&--flex {
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		flex-wrap: wrap;
		gap: 10px;

		#{$_root}__item {
			margin: 0;
			flex: 0 1 auto;
			width: auto;
			max-width: none;
		}
	}

	/* カラム寄せ */
	&--left {
		justify-content: flex-start;
	}

	&--right {
		justify-content: flex-end;
	}

	/* 上下接合（併用可） */
	&--collapse {
		@include m.mq-sp {
			#{$_root}__item + #{$_root}__item {
				margin-top: 0;
			}
		}

		@include m.mq-pc {
			&#{$_root}--2up,
			&#{$_root}--3up,
			&#{$_root}--4up {
				row-gap: 0;
			}
		}
	}
}
