@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	image link
----------*/
$_root: '.c-imagelink';

.c-imagelink {
	&__inner {
		display: block;
		width: 100%;
		position: relative;
		text-decoration: none;
		color: var(--color-text);
	}

	&__title {
		position: absolute;
		top: 20px;
		left: 30px;
		z-index: 3;
		font-weight: 500;
		font-size: 2rem;
		line-height: var(--line-height-s);
		color: #fff;
	}

	&__text {
		margin-top: 20px;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: var(--line-height-l);
	}

	&__image {
		position: relative;

		> figure {
			display: block;
			width: 100%;
			border-radius: 6px;
			position: relative;
			overflow: hidden;
			z-index: 1;

			> img {
				display: block;
				width: 100%;
				height: 100%;
				object-position: center;
				object-fit: cover;
			}

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 82px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				background-image: linear-gradient(rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
			}
		}
	}

	&__icon {
		&::after {
			@include m.link-icon;
			width: 40px;
			height: 40px;
			position: absolute;
			bottom: 10px;
			right: 10px;
			z-index: 3;
			background-color: #fff;
			font-size: 14px;
			line-height: 40px;
			color: var(--color-text);
		}
	}

	> a[target="_blank"] {
		#{$_root}__icon::after {
			content: map-get(m.$icons, 'blank');
		}
	}


	@include m.mq-pc {
		&__title {
			font-size: 2.6rem;
		}

		&__text {
			font-size: 1.6rem;
		}

		&__icon {
			&::after {
				bottom: 15px;
				right: 15px;
			}
		}
	}

	@include m.mq-hover {
		#{$_root}__image {
			> figure {
				> img {
					transition: transform 0.3s ease-out;
				}
			}
		}
		&__inner {
			&:hover {
				opacity: 1;

				#{$_root}__image {
					> figure {
						> img {
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}
}

// 2カラムPCレイアウト
@include m.mq-pc {
	.g-grid--2up {
		#{$_root} {
			&__title {
				top: 30px;
				left: 34px;
				font-size: 3.2rem;
			}

			&__icon {
				&::after {
					width: 60px;
					height: 60px;
					bottom: 20px;
					right: 20px;
					line-height: 60px;
				}
			}
		}
	}
}
