@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	news
----------*/
$_root: '.c-news';

.c-news {
	&__item {
		border-bottom: 1px solid var(--color-border);

		> a,
		> span {
			display: block;
			text-decoration: none;
			color: var(--color-text);
		}
	}

	&__detail {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		gap: 16px 28px;
		padding: 20px 0;
		position: relative;
	}

	&__date {
		flex: 0 0 auto;
		font-family: var(--font-raj);
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 1;
		transform: translateY(0.1em);
	}

	&__tag {
		flex: 0 1 auto;
		line-height: 1;
	}

	&__headline {
		flex: 1 1 100%;
		font-size: 1.6rem;
		line-height: var(--line-height-m);

		> p {
			display: inline-block;

			> span {
				font-family: var(--font-raj);
				font-weight: 500;
			}
		}

		i[class^='icon-'],
		i[class^='tagicon-'] {
			margin: 0 4px;
			transform: translateY(0.15em);
		}
	}

	&__new {
		display: inline-block;
		width: 54px;
		height: 20px;
		margin-left: 4px;
		border: 1px solid var(--color-border);
		border-radius: 10px;
		vertical-align: middle;
		background-color: #fff;
		text-align: center;
		font-size: 1.2rem;
		line-height: 18px;
	}

	@include m.mq-pc {
		&__detail {
			flex-wrap: nowrap;
			align-items: flex-start;
			padding: 30px;
		}
		&__date {
			min-width: 6em;
			font-size: 1.8rem;
			transform: translateY(0.35em);
		}
		&__tag {
			min-width: 110px;
		}
		&__headline {
			flex-basis: auto;
			font-size: 1.8rem;
		}
		&__new {
			width: 64px;
			font-size: 1.4rem;
		}
	}
}
