@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	deflist
----------*/
$_root: '.c-deflist';

.c-deflist {
	border-bottom: 1px solid var(--color-border);

	&__item {
		padding: 24px 0;
		border-top: 1px solid var(--color-border);

		dt {
			font-weight: 500;
			font-size: 1.6rem;
			line-height: var(--line-height-m);
		}

		dd {
			font-size: 1.4rem;
			line-height: var(--line-height-l);
		}
	}

	@include m.mq-sp {
		&__item {
			dd {
				margin-top: 26px;
			}
		}
	}

	@include m.mq-pc {
		&__item {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			gap: 0 50px;
			padding: 30px 0;

			dt {
				flex: 0 0 percentage( math.div(1, 4) );
				font-size: 1.8rem;
			}

			dd {
				flex: 1 1 auto;
				font-size: 1.6rem;
			}

			&--1t1 {
				dt {
					flex: 0 0 calc(percentage(math.div(1, 2)) - 25px);
				}	
			}
		}

		// &--1to2 {
		// 	#{$_root}__detail {
		// 		flex: 0 0 percentage( math.div(1, 3) );
		// 	}
		// }
	}
}
