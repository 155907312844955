@use 'sass:math';
@use '../../mixin' as m;

/*----------
	tag icon
----------*/
[class^="tagicon-"]{
	&::after {
		display: inline-block;
		width: 38px;
		height: 22px;
		border-radius: 19px;
		font-family: var(--font-raj);
		font-weight: bold;
		font-size: 1.4rem;
		line-height: 22px;
		text-align: center;
	}
}

.tagicon-pdf {
	&::after {
		content: "PDF";
		background-color: #FF0000;
		color: #fff;
	}
}

.tagicon-zip {
	&::after {
		content: "ZIP";
		background-color: #222;
		color: #fff;
	}
}
