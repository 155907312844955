@use 'sass:math';
@use '../../_mixin' as m;

/* --------------------------------------------------------
* 上書き用　override
* -------------------------------------------------------*/

// 共通パーツ

/* 見出し */
.eirHeading,
.s_eirHeading {
	font-family: var(--font-serif);
}
.s_eirHeading::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 1px;
    background: var(--color-prim);
    border-left: none;
    bottom: 0;
    left: 0;
    padding: 0;

	@include m.mq-pc {
		width: 100px;
	}
}

/* サブ見出し */
.qir .qirContent_label {
	padding: 20px;
	border-top: 1px solid #222;
	border-bottom: 1px dashed #888;
	position: relative;
	background-color: #fff;
	font-weight: 500;
	font-size: 2.2rem;
	line-height: var(--line-height-s);
	color: var(--color-text);

	@include m.mq-pc {
		font-size: 3.2rem;
		padding: 24px 40px;
	}
}
/* IRニュース */
.s_eirList_item {
	> a {
		text-decoration: none;
		color: var(--color-text);
	}
}
.s_eirModule_eirItem,
.eirItem_title_link {
	padding: 20px 0;

	&:hover {
		opacity: 1;
	}

	@include m.mq-pc {
		padding: 30px;
	}

	@include m.mq-hover {
		transition: opacity 0.2s linear;
		&:hover {
			opacity: 0.7;
		}
	}
}
.s_eirModule_date_time {
	display: block;
	font-family: var(--font-raj);
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 28px;

	@include m.mq-pc {
		min-width: 4em;
		font-size: 1.8rem;
	}
}
.s_eirModule_category {
	margin-left: 28px;
	max-width: 100%;
	min-width: 110px;
	height: 28px;
	vertical-align: middle;
	color: var(--color-text);

	@include m.mq-pc {
		flex: 0 1 110px;
	}
}
.s_eirModule_category_icon {
	margin-top: -2px;
	display: block !important;
	padding: 0 8px !important;
	border: 1px solid #ddd !important;
	border-radius: 6px !important;
	overflow: hidden;
	background-color: #fff !important;
	text-align: center !important;
	text-decoration: none !important;
	white-space: nowrap !important;
	text-overflow: ellipsis !important;
	font-size: 1.4rem !important;
	line-height: 26px !important;
	color: inherit !important;
}
.s_eirModule_type_icon-pdf {
	height: 22px;
	border-radius: 19px;
	background-color: #FF0000 !important;
	text-align: center;
	font-family: var(--font-raj) !important;
	font-weight: bold;
	font-size: 1.4rem !important;
	line-height: 22px !important;
}

.s_eirModule_titleBox {
	margin-top: 16px;

	@include m.mq-pc {
		margin: 0 0 0 40px;
	}
}
.s_eirModule_title_link,
.s_eirModule_title_label {
	font-size: 1.4rem;
	line-height: var(--line-height-m);

	@include m.mq-pc {
    font-size: 1.6rem;
	}

	@include m.mq-hover {
		transition: opacity 0.2s linear;
		&:hover {
			opacity: 0.7;
		}
	}
}
.s_eirModule_size_item {
	font-family: var(--font-raj);
	font-weight: 500;
}
.s_eirModule_comment {
	margin: 8px 0 0 0;

	@include m.mq-pc {
		margin: 15px 0 0 0;
	}
}
.s_eirList_item {
	position: relative;
	padding-top: 0 !important;
	margin-top: 0 !important;
}
.s_eirList_item a::after {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	position: absolute;
	left: 0;
	bottom: 0;
	transform-origin: center right;
	transform: scaleX(0);
	background-color: var(--color-text-link);
	transition: transform 0.3s ease-in-out;
}

.s_eirList_item {
	border-bottom: 1px solid var(--color-border) !important;
	border-top: none !important;

	&::before {
		content: none !important;
	}
}
.eir[class*=area_governance] .s_eirList_item {
	text-align: left;
}
.s_yearController {
	background-color: #fff;
	@include m.mq-pc {
		width: 300px;
	}
}
.s_yearController::after {
	border-bottom: 2px solid #222;
    border-right: 2px solid #222;
}
.s_eirSelect {
	border-radius: 0;
	padding-left: 30px !important;
	border-color: var(--color-border);
	@include m.mq-pc {
		min-width: 300px;
	}
}
.s_newsTypeController {
	@include m.mq-pc {
		position: relative;
	}
}
.s_eirTab {
	border: none !important;
	@include m.mq-pc {
		position: absolute;
		right: 0;
		top: -84px;
		flex-wrap: nowrap;
		gap: 10px;
		justify-content: flex-end;
	}
}
.s_eirTab li {
	border: none !important;
	background-color: transparent !important;
	@include m.mq-pc {
		width: auto !important;
	}
}
.s_eirTab li.active a,
.s_eirTab li:hover a {
	color: #fff !important;
	background-color: #222 !important;
}
.s_eirTab li a {
	color: #222 !important;
	padding: 0 10px !important;
	display: block;
    height: 42px;
    border-radius: 42px;
	border: 1px solid #222;
	background-color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 42px;

	&:hover {
		opacity: 1 !important;
	}
	@include m.mq-pc {
		font-size: 1.5rem;
		padding: 0 30px !important;
	}
}


/* pdfマークエリア */
.eir_pdfNote {
	margin-top: 40px;
}
.eir_pdfNote_item:first-child {
	margin-bottom: 20px;
}
.eir_pdfNote_item {
	color: #333;
	line-height: var(--line-height-m);
}
.eir_pnexNote_item a {
	color: var(--color-text-link);
	transition: color .2s linear;
}

/* 注釈リスト */
.qir .qirBox_asteriskNote {
	line-height: var(--line-height-m);

	> li {
		&::before {
			content: '※';
			display: inline-block;
			margin-right: 0.5em;
		}

		+ li {
			margin-top: 18px;
		}
	}
}

/* ご利用上の注意 */
.qir .qirBox_carefulNote_title {
	margin-bottom: 15px;
}
// リスト
.qir .qirBox_carefulNote_list {
	> li {
		+ li {
			margin-top: 20px;
		}
	}
}
.qir .qirBox_pnexNote_text {
	line-height: 2;
}
.qir .pnexIcon {
	padding-top: 40px;
}

/* テーブル */
.qir .qirContent_table {
	thead {
		th {
			background-color: var(--color-text-caption);
		}
	}
}

/* EIRリスト > アイテム（訂正） */
.eirItem_child {
	padding-left: 0;
	margin-bottom: 0 !important;

	@include m.mq-pc {
		padding-left: 30px;
	}
}
.eirItem_child .s_eirModule_eirItem {
	margin: 30px 0;
	padding: 0;
	position: relative;
	
	&::before {
		content: "";
		display: block;
		position: absolute;
		height: 100%;
		width: 0;
		left: 0;
		top: 0;
		border-left: 1px solid #979797;
	}
}

.eirItem_child .s_eirModule_titleBox {
	margin-left: 0;

	@include m.mq-pc {
		margin-left: 40px;
	}
}
.eirItem_child .s_eirModule_titleBox::before{
	display: none !important;
}

@media screen and (max-width: 768px) {
	.eirItem_child .eirItem_head {
			display:none;
	}
}

.s_eirList_item:has(+ .eirItem_child) {
	border-bottom: none !important;
	margin-bottom: -30px;
}

/* スクロールテーブル */
.g-ir-spscr-container{
	@include m.mq-sp {
		.qirContent {
			&::before {
				content: "";
				display: block;
				margin-bottom: 12px;
				padding-top: 32px;
				width: 100%;
				max-width: 160px;
				background: url(/assets/images/table-swipe.svg) no-repeat center;
				background-size: contain;
			}
		}

		.qirContent_table {
			overflow-x: auto;
			table {
				width: 900px;
			}
		}
	}
}


/* トップ IRニュース */
.g-irTopNews-container {

}

/* トップ 最新の決算資料 */
.g-irTopDoc-container {

}

/* IRニュース一覧 */
.g-irNews-container {

}

/* トップ IRニュース */
.g-irTopNews-container {

}

/* IRカレンダー */
.g-irCalendar-container {

}

/* コーポレート・ガバナンス */
.g-irGovernance-container {
	.eirItem_title_link {
		padding: 30px 0;
	}
	.s_eirModule_titleBox {
		margin-left: 0;
	}
}

/* 決算短信 */
.g-irTanshin-container {
	.s_eirList_item a::after {
		content: "";
		display: none;
		width: 0;
		height: 0;
		position: static;
		left: 0;
		bottom: 0;
	}
}

/* 決算説明会資料 */
.g-irPresentation-container {

}

/* 有報証券報告書 */
.g-irYuho-container {
	.s_eirList_item a::after {
		content: "";
		display: none;
		width: 0;
		height: 0;
		position: static;
		left: 0;
		bottom: 0;
	}
}

/* 株主通信 */
.g-irMeeting-container {

}

/* 株主総会*/
.g-irMeeting-container {

}

/* 財務ハイライト */
.g-irHighlight-container {

	.qirArea_wrapper {
		margin-bottom: 100px;

		@include m.mq-pc {
			margin-bottom: 80px;
		}
	}

	.qir {
		.qirArea {
			.qirBox {
				margin-top: 40px;
			}
		}
	}
}

/* 配当の推移 */
.g-irDividend-container {
	.qir {
		.qirContent_table {
			table {
				@include m.mq-pc {
					margin-bottom: 50px;
				}
			}

			tbody th {
				background-color: var(--color-text-caption);
				color: #FFF;
			}
		}

		// リスト
		.qirBox_carefulNote_list {
			> li {
				+ li {
					margin-top: 5px;
				}
			}
		}
	}
}

/* 一括ダウンロードボタン */
.g-irZip-button-container {
	.c-button__inner {
		min-height: 64px;

		> span {
			font-weight: normal;
		}

		&::after {
			font-family: iconfont !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			content: "\ea14";
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			right: 28px;
			font-size: 16px;
			transform: translateY(-50%);

			@include m.mq-pc {
				right: 40px;
			}
		}
	}
}

.excelArea_button {
	margin-top: 40px;
    width: auto;
    position: relative;

	display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid #222 !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #222;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
    text-decoration: none;
	cursor: pointer;

	min-height: 48px;
    border-radius: 4px;
    background-color: #fff;
    font-weight: 500;
    color: var(--color-text) !important;
	text-decoration: none !important;

	@include m.mq-pc {
		margin-top: 80px;
	}
}

.excelArea_button_link {
	padding: 12px 36px 12px 18px !important;
    font-size: 1.4rem !important;
	color: inherit !important;
	text-decoration: none !important;
	line-height: inherit !important;

	&:hover {
		background-color: inherit !important;
	}
	&::before {
		display: none !important;
	}
	&::after {
		font-family: iconfont !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		line-height: 1 !important;
		content: "\ea09" !important;
		display: block !important;
		width: 14px !important;
		height: 14px !important;
		position: absolute !important;
		top: 50% !important;
		right: 20px !important;
		font-size: 14px !important;
		transform: translateY(-50%) !important;
		width: 10px !important;
    	height: 10px !important;
    	right: 14px !important;
    	font-size: 10px !important;
		border: none !important;
		
	}


}

/* ハイライト(通期)
  ir/finance/highlight.html
  ir/finance/highlight2.html
  ir/finance/highlight3.html
*/
.qir_area_highlight_010,
.qir_area_highlight_011,
.qir_area_highlight_012 {
	.qirSection_wrapper {
		> .qirContent:not(:first-child) {
			@include m.mq-pc {
				margin-top: 80px;
			}
		}
	}
}

/* ハイライト(四半期)
  ir/finance/highlightq.html
  ir/finance/highlightq2.html
  ir/finance/highlightq3.html
*/
.qir_area_highlight_013,
.qir_area_highlight_014,
.qir_area_highlight_015 {
	.qirArea-responsive .qirContent {
		@media screen and (max-width: 750px) {
			margin-bottom: 0 !important;
		}
	}
}
