@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button
----------*/
$_root: '.c-button';

.c-button {
	&__inner {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 65px;
		border: 1px solid #222;
		border-radius: 6px;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		overflow: hidden;
		background-color: #222;
		box-shadow: 0 2px 15px rgba(0,0,0,0.12);
		text-decoration: none;
		color: #fff;

		> span {
			padding: 12px 54px 12px 30px;
			text-align: left;
			font-weight: 500;
			font-size: 1.6rem;
			line-height: var(--line-height-s);
			color: inherit;
		}

		&::after {
			@include m.iconfont-default;
			content: map-get(m.$icons, 'chev-right');
			display: block;
			width: 14px;
			height: 14px;
			position: absolute;
			top: 50%;
			right: 20px;
			font-size: 14px;
			transform: translateY(-50%);
		}

		&[target="_blank"] {
			&::after {
				content: map-get(m.$icons, 'blank');
			}
		}

		&[target="_blank"][href*=".pdf"] {
			> span {
				padding-right: 78px;
			}

			&::after {
				content: "PDF";
				width: 38px;
				height: 22px;
				border-radius: 19px;
				background-color: #FF0000;
				text-align: center;
				font-family: var(--font-raj) !important;
				font-weight: bold;
				font-size: 1.4rem;
				line-height: 22px;
			}
		}

		&[href*=".zip"] {
			> span {
				padding-right: 78px;
			}

			&::after {
				content: "ZIP";
				width: 38px;
				height: 22px;
				border: 1px solid #fff;
				border-radius: 19px;
				background-color: transparent;
				text-align: center;
				font-family: var(--font-raj) !important;
				font-weight: bold;
				font-size: 1.4rem;
				line-height: 22px;
			}
		}
	}

	@include m.mq-hover {
		&__inner {
			transition: opacity 0.2s linear;
			cursor: pointer;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

/* バリエーション */

/* 任意アイコン */
.c-button {
	&--icon {
		#{$_root}__inner {
			&::after {
				content: none;
				display: none;
			}
		}

		#{$_root}__icon {
			@include m.iconfont-default;
			display: block;
			width: 14px;
			height: 14px;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			font-size: 14px;
		}
	}
}

/* ボタン小 */
.c-button {
	&--s {
		#{$_root}__inner {
			min-height: 48px;
			border-radius: 4px;
			background-color: #fff;
			font-weight: 500;
			font-size: 1.4rem;
			color: var(--color-text);

			> span {
				padding: 12px 36px 12px 18px;
				font-size: 1.4rem;
			}

			&::after {
				width: 10px;
				height: 10px;
				right: 14px;
				font-size: 10px;
			}
		}
	}
}
