@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	image link feature
----------*/
$_root: '.c-imagelink-feature';

.c-imagelink-feature {
	&__inner {
		display: block;
		width: 100%;
		position: relative;
		text-decoration: none;
		color: var(--color-text);
	}

	&__title {
		position: absolute;
		top: 32px;
		left: 32px;
		z-index: 3;
		font-weight: 500;
		font-size: 3.2rem;
		line-height: var(--line-height-s);
		color: #fff;
	}

	&__text {
		margin-top: 30px;
		font-weight: 500;
		font-size: 1.8rem;
		line-height: var(--line-height-m);
	}

	&__image {
		position: relative;

		> figure {
			display: block;
			width: 100%;
			border-radius: 6px;
			position: relative;
			overflow: hidden;
			z-index: 1;

			> img {
				display: block;
				width: 100%;
				height: 100%;
				object-position: center;
				object-fit: cover;
			}

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 140px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				background-image: linear-gradient(rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
			}
		}
	}

	&__icon {
		&::after {
			@include m.link-icon;
			position: absolute;
			bottom: -20px;
			right: 20px;
			z-index: 3;
		}

		&.color-green::after {
			background-color: var(--color-theme-green);
		}
		&.color-yellow::after {
			background-color: var(--color-theme-yellow);
		}
		&.color-cyan::after {
			background-color: var(--color-theme-cyan);
		}
		&.color-orange::after {
			background-color: var(--color-theme-orange);
		}
		&.color-blue::after {
			background-color: var(--color-theme-blue);
		}
		&.color-lime::after {
			background-color: var(--color-theme-lime);
		}
		&.color-slateblue::after {
			background-color: var(--color-theme-slateblue);
		}
	}

	> a[target="_blank"] {
		#{$_root}__icon::after {
			content: map-get(m.$icons, 'blank');
		}
	}

	@include m.mq-hover {
		#{$_root}__image {
			> figure {
				> img {
					transition: transform 0.3s ease-out;
				}
			}
		}
		&__inner {
			&:hover {
				opacity: 1;

				#{$_root}__image {
					> figure {
						> img {
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}
}
