@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	figure
----------*/
$_root: '.c-figure';

.c-figure {
	&__image {
		text-align: center;

		> img {
			max-width: 100%;
			width: auto;
		}
	}

	&__caption {
		margin-top: 20px;
		text-align: left;
		font-size: 1.4rem;
		line-height: var(--line-height-m);
		color: var(--color-text-caption);
	}
}
