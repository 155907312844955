@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button floating
----------*/
$_root: '.c-button-floating';

.c-button-floating {
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 100;
	background-color: #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,0.2);
	cursor: pointer;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity 0.2s linear, visibility 0.2s linear;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	&::before {
		@include m.iconfont-default;
		content: map-get(m.$icons, 'chev-down');
		display: block;
		width: 12px;
		height: 12px;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 12px;
		color: var(--color-text);
		transform: translate(-50%, -50%);
	}

	// 種類が増えるならmodifierに記載
	// &--chev-down {
	// }

	@include m.mq-pc {
		width: 70px;
		height: 70px;

		&::before {
			width: 18px;
			height: 18px;
			font-size: 18px;
		}
	}

	@include m.mq-hover {
		&:hover {
			opacity: 0.7;
		}
	}
}
