@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	caption
----------*/

.c-caption {
	font-size: 1.2rem;
	line-height: var(--line-height-l);
	color: var(--color-text);

	@include m.mq-pc {
		font-size: 1.4rem;
	}
}
