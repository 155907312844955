@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	image separate
----------*/
$_root:'.c-image-separate';

.c-image-separate {
	// &__title {
	// 	font-family: var(--font-serif);
	// 	font-weight: 500;
	// 	font-size: 2rem;
	// 	line-height: var(--line-height-m);
	// }

	&__text {
		margin-top: 20px;
		font-size: 1.4rem;
		line-height: var(--line-height-xl);
	}

	// &__link {
	// 	margin-top: 36px;
	// }

	@include m.mq-sp {
		&__image {
			margin-bottom: 20px;
		}
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 60px;
		}

		&__image {
			flex: 0 0 calc(50% - 30px);
		}

		&__content {
			flex: 1 0 calc(50% - 30px);
		}

		// &__title {
		// 	font-size: 3.2rem;
		// }

		&__text {
			margin-top: 0;
			font-size: 1.6rem;
		}

		// &__link {
		// 	margin-top: 40px;
		// }

		&--1t2 {
			#{$_root}__image {
				flex-basis: calc(33.3333% - 30px);
			}
		}

		&--1t3 {
			#{$_root}__image {
				flex-basis: calc(25% - 30px);
			}
			#{$_root}__title {
				font-size: 2.4rem;
			}
		}

		&--reverse {
			#{$_root}__inner {
				flex-direction: row-reverse;
			}
		}
	}
}
