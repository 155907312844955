@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	list
----------*/
$_root:'.c-list';

.c-list {
	> li {
		+ li {
			margin-top: 10px;
		}
	}

	&--dot {
		> li {
			padding-left: 1em;
			text-indent: -0.65em;

			&::before {
				content: '・';
				display: inline-block;
				margin-right: 0.25em;
				background-color: var(--color-txt);
			}
		}
	}

	&--sqchev {
		> li {
			padding-left: 30px;
			position: relative;
			font-weight: 500;
			font-size: 1.6rem;

			&:has(a[target="_blank"]) {
				&::before {
					content: map-get(m.$icons, 'blank');
				}	
			}

			&::before {
				@include m.iconfont-default;
				content: map-get(m.$icons, 'chev-sq-right');
				display: block;
				position: absolute;
				top: 0.35em;
				left: 0;
				font-size: 18px;
				color: var(--color-txt);
			}

			> a {
				text-decoration: none;
				color: var(--color-txt);
			}
		}
	}
}
