@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	table
----------*/
$_root: '.c-table';

.c-table {
	&__header {
		vertical-align: middle;
		background-color: #fff;
		font-weight: 500;
		font-size: 1.6rem;
		text-align: center;
		color: var(--color-text);
	}

	&__header-sub {
		vertical-align: middle;
		background-color: #E1EBEF;
		font-weight: 500;
		font-size: 1.6rem;
		text-align: center;
		color:  var(--color-text);
	}

	&__caption {
		margin-bottom: 8px;
		text-align: left;
		font-weight: normal;
	}

	@include m.mq-sp {
		&--scrollable {
			$gutter: math.div(20, 375);

			&::before {
				content: '';
				display: block;
				margin-bottom: 12px;
				padding-top: 25px;
				background: url(/assets/images/table-swipe.svg) no-repeat left;
				background-size: contain;
			}

			#{$_root}__inner {
				margin-left: percentage( $gutter * -1 );
				margin-right: percentage( $gutter * -1 );
				padding-left: percentage( $gutter );
				overflow: auto;
			}

			#{$_root}__body {
				width: 1000px;
				padding-bottom: 10px;
				padding-right: percentage( $gutter );
			}
		}
	}
}
