@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	separator
----------*/

.c-separator {
	display: block;
	margin: 60px auto;
	border-top: 1px solid var(--color-border);

	&--dashed {
		border-top-style: dashed;
		border-color: var(--color-border);
	}

	@include m.mq-pc {
		margin: 80px auto;
	}
}
