@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	anchor container
----------*/
$_root: '.g-anchor-container';

.g-anchor-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0 10px;

	> * {
		margin-top: -1px;
	}

	@include m.mq-pc {
		grid-template-columns: repeat(4, 1fr);
		gap: 0 50px;

		&--5up {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}
