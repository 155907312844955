@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	sitemap link
----------*/
$_root: '.c-sitemaplink';

.c-sitemaplink {
	> dl {
		> dt {
			> a,
			> span {
				display: flex;
				align-items: center;
				min-height: 80px;
				padding: 10px 52px 16px 26px;
				border-top: 1px solid #000;
				border-bottom: 1px dashed #888;
				position: relative;
				background-color: #fff;
				text-decoration: none;
				font-weight: 500;
				font-size: 1.8rem;
				line-height: var(--line-height-s);
				color: var(--color-text);
			}

			> a {
				&::after {
					@include m.iconfont-default;
					content: map-get(m.$icons, 'chev-right');
					display: block;
					width: 14px;
					height: 14px;
					position: absolute;
					top: 50%;
					right: 20px;
					font-size: 14px;
					transform: translateY(-50%);
				}

				&[target="_blank"]::after {
					content: map-get(m.$icons, 'blank');
				}
			}
		}

		> dd {
			margin-top: 24px;
			> p {
				padding-left: 20px;
			}
		}
	}

	@include m.mq-pc {
		> dl {
			> dt {
				> a,
				> span {
					min-height: 100px;
				}
			}
		}
	}
}

.c-sitemaplink-list {
	a {
		display: block;
		text-decoration: none;
		color: var(--color-txt);
		line-height: var(--line-height-m);
	}

	> li,
	&__cattop {
		> a {
			padding-left: 30px;
			position: relative;
			font-weight: 500;
			font-size: 1.6rem;

			&::before {
				@include m.iconfont-default;
				content: map-get(m.$icons, 'chev-sq-right');
				display: block;
				position: absolute;
				top: 0.25em;
				left: 0;
				font-size: 18px;
				color: var(--color-txt);
			}

			&[target="_blank"] {
				&::before {
					content: map-get(m.$icons, 'blank');
				}
			}
		}
	}

	&__sub {
		margin-top: 16px;
		padding-left: 30px;

		a {
			font-size: 1.2rem;
		}

		li + li {
			margin-top: 8px;
		}
	}

	@include m.mq-sp {
		> li + li {
			margin-top: 20px;
		}
	}

	@include m.mq-pc {
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;

		> li,
		&__cattop {
			> a {
				font-size: 1.8rem;
			}
		}

		&__sub {
			a {
				font-size: 1.4rem;
			}
		}
	}
}
