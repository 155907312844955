@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	feature wide
----------*/
$_root: '.c-feature-wide';

.c-feature-wide {
	width: 100%;
	height: 400px;
	position: relative;
	overflow: hidden;
	color: #fff;

	&::after {
		content: "";
		display: block;
		width: 6px;
		height: 100%;
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		background-color: var(--color-theme-green);
	}

	&__image {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}
	}

	&__title {
		width: 272px;
		position: absolute;
		top: 58px;
		left: calc(5.3% + 20px);
		z-index: 5;
	}

	&__text {
		padding-right: 5.3%;
		position: absolute;
		top: 163px;
		left: calc(5.3% + 20px);
		z-index: 5;
		font-size: 1.6rem;
		line-height: var(--line-height-xl);
	}

	&__btn {
		position: absolute;
		bottom: 40px;
		right: 5.3%;
		z-index: 5;

		> a {
			height: 80px;
			padding-right: 95px;
			font-weight: 500;
			font-size: 2.4rem;
			line-height: 80px;
			color: #fff;

			&::after {
				@include m.link-icon;
				position: absolute;
				top: 0;
				right: 0;
				background-color: var(--color-theme-green);
			}

			> span {
				display: inline-block;
				border-bottom: 2px solid #fff;
				line-height: var(--line-height-m);
			}
		}
	}

	@include m.mq-pc {
		height: 400px;

		&::after {
			width: 10px;
		}

		&__title {
			width: 412px;
			top: 84px;
			left: 120px;
		}

		&__text {
			padding-right: 120px;
			top: 247px;
			left: 120px;
			font-size: 1.8rem;
		}

		&__btn {
			bottom: 60px;
			right: 100px;

			> a {
				height: 100px;
				padding-right: 120px;
				line-height: 100px;

				&::after {
					width: 100px;
					height: 100px;
					font-size: 23px;
					line-height: 100px;
				}
			}
		}
	}
}

// about
#{$_root}--about {
	&::after {
		background-color: var(--color-theme-yellow);
	}

	#{$_root}__title {
		width: 241px;
	}

	#{$_root}__text {
		top: 158px;
	}

	#{$_root}__btn {
		> a {
			&::after {
				content: map-get(m.$icons, 'blank');
				background-color: var(--color-theme-yellow);
			}
		}
	}

	@include m.mq-pc {
		#{$_root}__title {
			width: 361px;
		}

		#{$_root}__text {
			top: 225px;
		}
	}
}

// approach
#{$_root}--approach {
	&::after {
		background-color: var(--color-theme-cyan);
	}

	#{$_root}__title {
		width: 260px;
	}

	#{$_root}__text {
		top: 140px;
	}

	#{$_root}__btn {
		> a {
			&::after {
				background-color: var(--color-theme-cyan);
			}
		}
	}

	@include m.mq-pc {
		#{$_root}__title {
			width: 364px;
		}

		#{$_root}__text {
			top: 200px;
		}
	}
}
