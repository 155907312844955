@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	tag
----------*/

.c-tag {
	display: inline-block;
	max-width: 100%;
	min-width: 110px;
	height: 28px;
	vertical-align: middle;
	color: var(--color-text);

	> a,
	> span {
		display: block;
		padding: 0 8px;
		border: 1px solid #ddd;
		border-radius: 6px;
		overflow: hidden;
		background-color: #fff;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 1.4rem;
		line-height: 26px;
		color: inherit;
	}

	> a {
		border-color: #222;
		background-color: #222;
		color: #fff;
	}
}
