@use 'sass:math';
@use '../../mixin' as m;
/*----------
	remarks
----------*/
$_root: '.c-remarks';

.c-remarks {
	font-size: 1.2rem;
	line-height: var(--line-height-m);

	> li {
		padding-left: 1.5em;
		text-indent: -0.75em;

		&::before {
			content: '※';
			display: inline-block;
			margin-right: 0.5em;
		}

		+ li {
			margin-top: 18px;
		}
	}

	&--order {
		counter-reset: orderlist;

		> li {
			padding-left: 2.5em;
			text-indent: -1.25em;

			&::before {
				counter-increment: orderlist;
				content: '※'counter(orderlist);
				min-width: 0.75em;
			}
		}
	}

	@include m.mq-pc {
		font-size: 1.4rem;
	}
}
