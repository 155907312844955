@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-secondary
----------*/

.c-heading-secondary {
	padding: 20px;
	border-top: 1px solid #222;
	border-bottom: 1px dashed #888;
	position: relative;
	background-color: #fff;
	font-weight: 500;
	font-size: 2.2rem;
	line-height: var(--line-height-s);
	color: var(--color-text);

	@include m.mq-pc {
		font-size: 3.2rem;
		padding: 24px 40px;
	}
}
