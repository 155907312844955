@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-tertiary
----------*/


.c-heading-tertiary {
	padding-bottom: 20px;
	padding-left: 25px;
	border-bottom: 1px dashed #888;
	position: relative;
	font-weight: 500;
	font-size: 2rem;
	line-height: var(--line-height-s);
	color: var(--color-text);

	&::after {
		content: '';
		display: block;
		width: 5px;
		height: calc(100% - 20px);
		position: absolute;
		top: 0;
		left: 0;
		background-color: #888;
	}

	@include m.mq-pc {
		padding-bottom: 14px;
		font-size: 2.8rem;
	}
}
