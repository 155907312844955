@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button panel
----------*/
$_root: '.c-button-panel';

.c-button-panel {
	padding: 20px;
	border-radius: 6px;
	background-color: #fff;

	&__title {
		font-weight: 500;
		font-size: 1.4rem;
		line-height: var(--line-height-m);

		> em {
			font-size: 1.8rem;
		}
	}

	&__button {
		display: flex;
		justify-content: flex-end;
		gap: 18px;
	}

	@include m.mq-sp {
		&__button {
			flex-wrap: wrap;
			margin-top: 24px;

			> div {
				flex: 1 1 100%;
			}
		}
	}

	@include m.mq-pc {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0 30px;
		padding: 30px;

		&__title {
			flex: 1 1 315px;
		}

		&__button {
			flex: 1 1 800px;

			> div {
				flex: 1 1 50%;
				max-width: 530px;
			}
		}
	}
}
